header{
    background-color: rgb(36,50,59);
    color:white;
    padding: 10px;
    height: 80px;


}

header img {
    height: 60px;
   float:right;
}

header div{
    
   display: flex;

    height: 60px;
    align-items: center;
  
}

main {
    background-color: #eee;
    padding: 10px;
}

footer {
    background-color: rgb(36,50,59);
    color:white;
    text-align: center;
    font-size:10px;
    height: 120px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

footer ul {
    list-style: none;
    margin: 0px;
    padding: 0;
}
footer ul li{
    display: inline;
    padding: 10px;
}

footer ul li::after{
    content: "|";
    margin-left: 20px;
}

footer ul li:last-child::after {
    content: "";
}