textarea{
    min-height: 300px !important;
    background-color: red;
}

.attachment{
    border-bottom: 1px solid #555;
    padding-top: 5px;
}
.attachment div{
    display: flex;
    align-items: center;
}

.error{
    background-color: #F06B61;
    border: 1px solid #F52415;
    padding: 10px;
}

.success{
    background-color: #8EE969;
    border: 1px solid #55DB21;
    padding: 10px;
}


.rotation {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    0%{
       transform: rotate(0deg);
      }
   100%{
       transform: rotate(360deg);
      }
   }

.requiredInfo{
    font: size 12px;
}